.flip-clock {
    --fcc-flip-duration: 0.5s;
    --fcc-digit-block-width: 30px;
    --fcc-digit-block-height: 60px;
    --fcc-digit-font-size: 30px;
    --fcc-digit-color: #000;
    --fcc-label-font-size: 10px;
    --fcc-label-color: #ffffff;
    --fcc-background: #fff;
    --fcc-divider-color: black;
    --fcc-divider-height: 1px;
    --fcc-separator-size: 6px;
    --fcc-separator-color: red;
}

.flip-clock {
    font-family: 'Orbitron';
    font-weight: 700;
    margin-bottom: 2rem;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Orbitron';
    color: #fff;
}
.page {
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.page video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    width: 100%;
    height: 100%;
}


.page_content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.page h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.page h3 {
    font-weight: 300;
    max-width: 400px;
    margin-bottom: 3rem;
}

.alienz-text {
    font-size: 36px; /* adjust the size as needed */
  }
  
  .orange {
    color: #e07d39;
  }
  
  .white {
    color: white;
  }

@media (min-width: 900px) {
    .page h1 {
        font-size: 4rem;
    }
    .page h3 {
        max-width: 600px;
    }
    .flip-clock {
    --fcc-digit-block-width: 80px;
    --fcc-digit-block-height: 120px;
    --fcc-digit-font-size: 60px;
    --fcc-digit-color: #000;
    --fcc-label-font-size: 20px;
    }
}